//============ Card ===========//
.card {
  border: none;
}
.view-group {
 float: none;
 width: 100%;
 background-color: #fff;
 margin-bottom: 30px;
 -ms-flex: 0 0 100%;
 flex: 0 0 100%;
 max-width: 100%;
 padding: 0 1.0rem;
 border: 0;
}
.thumbnail
{
 margin-bottom: 30px;
 padding: 0px;
 -webkit-border-radius: 0px;
 -moz-border-radius: 0px;
 border-radius: 0px;
}

.item.list-group-item
{
 float: none;
 width: 100%;
 background-color: #fff;
 margin-bottom: 15px;
 -ms-flex: 0 0 100%;
 flex: 0 0 100%;
 max-width: 100%;
 padding: 0 0;
 border: 0;
}
.item.list-group-item .img-event {
 float: left;
 width: 25%;
}
.item.list-group-item .list-group-image
{
 margin-right: 10px;
}
.item.list-group-item .thumbnail
{
 margin-bottom: 0px;
 display: inline-block;
 border-bottom: 1px solid $accent;
}
.item.list-group-item .caption {
  float: left;
  width: 75%;
  margin: 0;
  padding-top: 2.5rem;
  h4 {
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4rem;
    }
}
.card-body {
 padding: 0.5rem;
}
.caption {
 font-size: 1.0rem;
 font-weight: 600;
 line-height: 1.2rem;
 h4 {
   font-family: "Open Sans Condensed", sans-serif;
   font-size: 1.1rem;
   font-weight: 600;
   line-height: 1.2rem;
 }
 h5 {
   font-size: 0.85rem;
   font-weight: 400;
   line-height: 1.2rem;
   font-style: italic;
  }
 .list-group-item-text {
   font-size: .8rem;
   font-weight: 300;
   line-height: 1.1rem;
   padding-right: 40px;
 }
 .lead {
   font-size: 1.0rem;
   font-weight: 400;
   text-align: center;
   margin-bottom: 5px;
 }
}
.btn-view {
 text-align: center;
 cursor: pointer;
 a {
     color: #999;
     line-height: 2.7rem;
     margin: 0 4px;
        &:hover, &:focus {
       color: $dark-blue;
     } 
     .active 
       {	
         color: $accent;	
       }
 }
}
.btn {
 padding: 0.375rem .5rem;
 font-size: 0.85rem;
 color: #ffffff;
}
.book-test  {
 text-align: center;
}
.mytext
{
 display: none;
 text-align: center;
}
.item.list-group-item:before, .item.list-group-item:after
{
 display: table;
 content: " ";
}
.item.list-group-item:after
{
 clear: both;
}
.img-event {
 margin: 0 auto;
}
.list-group-item {
  margin-top: -2px;
}

//======== BOOK BLOCK =============//
.book-block {
	border-bottom: 1px solid #ccc;
}
.book-desc {
	color: $dark-blue;
	font-family: $default-font;
	font-size: 1.0rem;
	  h3 {
		  font-size: 0.85rem;
			font-weight: 600;
			margin: 0;
			}
		p {
			font-size: 0.80rem;
			font-weight: 400;
			}
}
.bookcontainer {
  z-index: 1;
  perspective:3000px;
}
.bookcontainer .book:hover  {
  font-weight: 400;
  color: #2b2b2b;
  -webkit-transform: rotate3d(0,1,0,0deg);
   -moz-transform: rotate3d(0,1,0,0deg);
   transform: rotate3d(0,1,0,0deg);
  -webkit-transition: -webkit-transform .5s;
   -moz-transition: -moz-transform .5s;
    transition: transform .5s;
}
.bookcontainer .book {
  position: relative;
    display: block;
    width: 157px;
    height: 240px;
    margin: 5% auto;
    border-radius: 2px 4px 4px 2px;
    -webkit-transition: -webkit-transform .5s;
    -moz-transition: -moz-transform .5s;
    transition: transform .5s;
    -webkit-transform: rotate3d(0,1,0,35deg);
    -moz-transform: rotate3d(0,1,0,35deg);
    transform: rotate3d(0,1,0,35deg);
  	-webkit-transform-style: preserve-3d;
  	-moz-transform-style: preserve-3d;
  	transform-style: preserve-3d;
  	box-shadow: 12px 8px 9px 0 rgba(151,146,153,.4);
  }

.bookcontainer .book > div,
.bookcontainer .front > div {
  display: block;
  position: absolute;
  transform: rotateY(35eg);
}
.bookcontainer .front {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform .5s;
  -moz-transition: -moz-transform .5s;
  transition: transform .5s;
  -webkit-transform: translate3d(0,0,20px);
  -moz-transform: translate3d(0,0,20px);
  transform: translate3d(0,0,20px);
  z-index: 10;
}
.bookcontainer .front > div {
  width: 157px;
  height: 240px;
}
.bookcontainer .left-side{
	width: 24px;
	left: -7px;
  height: 240px;
  background-color: #efebe8;
  -webkit-transform: rotate3d(0,1,0,-90deg);
  -moz-transform: rotate3d(0,1,0,-90deg);
  transform: rotate3d(0,1,0,-90deg);
}
.bookcontainer .front:after {
  content: '';
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: -1px;
  width: 1px;
}
.bookcontainer .cover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 6px;
  bottom: 0;
  width: 3px;
  background: rgba(0,0,0,0.1);
  box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
}
.bookcontainer h2 {
	width: 240px;
	height: 40px;
	color: #555;
	font-size: 14px;
	line-height: 52px;
	padding-right: 12px;
	text-align: right;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(90deg) translateY(-40px);
  -moz-transform: rotate(90deg) translateY(-40px);
  transform: rotate(90deg) translateY(-40px);
}
.left-side h2 span:first-child {
  font-weight: 400;
  font-size: 13px;
  padding-right: 12px;
}
.left-side h2 span:last-child{
  font-family: $default-font;
}
//============ Book Details ============//@at-root
.book-wrapper {
  background-color: #fff;
  padding-bottom: 2.0rem;
  .book-block {
    border-bottom: none;
}
}
.book-meta-data {
    h2 {
			font-family: "Open Sans Condensed", sans-serif;
			font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.4rem;
		}
		.subtitle {
			font-size: 0.9rem;
			font-style: italic;
      font-weight: 400;
      line-height: 1.3rem;
		}
		p {
			font-size: 0.75rem;
			font-weight: 400;
      line-height: 1.0rem;
		}
}
.download-pdf-link {
  color: #555;
  border-top: 1px solid $accent;
    ul {
       list-style-type: none;
       padding-left: 0;
     }
     a {
       color: $dark-blue;
       &:hover, &:focus {
         color: $accent;
         text-decoration: underline;
       }
     }
    span {
     font-size: 0.85rem;
     padding: 0 10px;
    }
}
.fa-file-pdf-o {
  color: red
}
.related-content {
  background-color: #efebe8;
    h3 {
      font-size: 1.2rem;
      font-weight: 400;
      color: $dark-blue;
    }
}
//=========== Authors ==================//
.authors-item {
  figcaption {
     top: 0;
     padding: 5% 10px 10px 10px;
     background-color: rgba($accent,.8); 
     -webkit-transform: translateY(80%);
     transform: translateY(80%);
     h2 {
       font-size: 1.4rem;
       font-weight: 400;
       padding: 15px 0;
       &:hover {
         color:#000;
       }
     }
     p {
      font-size: 0.9rem;
      line-height: 1.2rem;
      color:$dark;
      padding-top: 50px; 
      a
        {
        color: #fff;
        font-style: italic;
        top: 200px;
        &:hover {
          color:#000;
        }
       } 
     }
  }
}

//============ Pagination ==============//
.book-pagination-container {
     background-color: #f5f5f5;
     padding: 0 0 0 10px;
     line-height: 1.6rem;
     width: 100%;
     font-size: .7rem;
     display: flex;
     height: 1.6rem;
 }
.book-pagination-container> div {
  float: left;
}
.pagination-container > ul.pagination {
  float: left;
  margin-left: 24%;
}
.book-pagination-container .pagination {
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
  float: left;
}
.items-per-page {
  padding-right: 10px;
  display: flex;
}
.paging_number {
padding-right: 4px;
}
