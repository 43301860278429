// @import "vars";
@import "carousel";
@import "book-view";
@import "footer";
@import "banner";

// @import "fonts";
// @font-face {
// 	font-family: "Open Sans Regular";
// 	src: url("../fonts/OpenSans-Regular.woff2") format("woff2");
// 	font-style: 400;
// 	font-weight: normal;
// }
// @font-face {
// 	font-family: "Open Sans Light";
// 	src: url("../fonts/OpenSans-Light.woff2") format("woff2");
// 	font-style: normal;
// 	font-weight: 200;
// }
// @font-face {
// 	font-family: "Open Sans Bold";
// 	src: url("../fonts/OpenSans-Bold.woff2") format("woff2");
// 	font-style: 700;
// 	font-weight: normal;
// }
@font-face {
  font-family: "Open Sans Condensed";
  src: url("../fonts/OpenSansCondensed-Light.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Font Awesome";
	src: url("../fonts/fontawesome-webfont.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}
::placeholder {
	color: #666;
}
body {
  font-size: 18px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: $default-font;
  overflow-x: hidden;
	color: $text;
	  a {
		  color: $accent;
			&:hover, &:focus { 
				color: blue;
		  }
	}
}
.top-line  {
	background-color: #fff;
	position: relative;
	height: 100px;
	z-index: 40;
}
.top-line.sticky {
	position: relative;
	height: 60px;
	width: 100%;
	position: fixed;
	transition: color .25s ease, background-color .25s ease;
	background-color: rgba(#fff, 0.9);
	border-bottom: 1px solid $accent;
}
.brand {
	padding: 4px 0 0 60px; 
	line-height: 100px;
	float: left;
}
.brand-sm {
	padding: 10px 30px; 
	line-height: 60px;
	float: left;
	transition: .25s ease;
}		
.logo {
	display: none;
	font-family: $default-font;
	font-weight: 700; 
	color: $dark;
	font-size: 22px;
	font-style: italic;
	text-decoration: none;
	position: absolute;
	padding-left: 30px;
	left: 0;
	line-height: 100px;
	  span {
		  color: $accent;
				&:hover, &:focus {
				text-decoration: none;
			}
		}		
	}		
.logo.sticky  {
	line-height: 60px;
	text-decoration: none;
	color: $dark;
}
//=========== Nain Menu ============
.main-menu  {
	padding: 0 100px;
	width: 100%;
	text-align: center;
	font-family: $default-font;
	font-weight: 700;
	z-index: 200;
	ul, li  {
	  list-style-type: none;
		padding: 0;
		margin: 0;
	li {
		 display: inline-block;
		 margin-right: -4px;
		 .active {
				  color: $accent;
				 }
		 &:hover 
			 a {
				  color: $accent
				 }
		a.sticky {
			line-height: 60px;
			  &:hover 
				   a {
					   color: $accent
				     }
		}
	a {
		font-size: .9rem;
	  color: $text;
	  text-decoration: none;
	  text-transform: uppercase;
		font-weight: 700;
		padding: 0 5px;
	  line-height: 100px;
		transition: color .25s ease, background-color .25s ease;
		}	
			&:last-child::after 
			  {
				content:'' ;
				color: inherit;
			  }
		    &::after {
					content:'/' ;
					font-weight: 300;
			    font-size: 1.2rem;
					color: $accent;
				}
	}
 }
}
.mobile-menu-button {
	color: $dark;
	text-align: right;
	cursor: pointer;
	line-height: 100px;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 700;
	white-space: nowrap;
	display: none;
	padding: 0 100px;
	&:hover {
		color: $accent;
	}
}
.mobile-menu-button.sticky {
	line-height: 60px;
}
.mobile-menu.sticky {
	top: 60px;
} 
.mobile-menu {
	display: none;
	background-color: lighten($dark, 3%);
	border-top: #333 1px solid;
	font-family: $default-font;
	font-size: 0.8rem;
	color: #ccc;
	z-index: 3000;
  position: fixed;
  top: 100px;
  left: 0px;
  width: 100%;
 	.top-menu {
		display: block;
	li {
		display: block;
		border-bottom: #666 1px solid;
		a.sticky {
			line-height: 40px;
		}
		.active {
			color: $accent;
		 }
		a {
			padding: 0;
			line-height: 40px;
			color: #ccc;
		}	
		&::after {
			content:'';
		}
		&:last-child {
			border-bottom: none;
		}
	}	
}
}
.search-wrap
	.search {
		position: absolute;
		padding: 0 30px 0 10px;
		right: 0;
		color: #666;
		line-height: 100px;
		cursor: pointer;
		transition: color .25s ease;
			&:hover {
				color: #666
		}	
}	
.search-wrap.sticky
  .search {
	  line-height: 60px;
}
.search-field.sticky {
	top: 60px;
}	
.search-field {
	  position: absolute;
	  left: 0;
	  top: 100px;
	  padding: 15px 30px;
	  background-color: lighten($accent, 5%);
	  width: 100%;
	  border-top: #333 1px solid;
	  z-index: 4;
		display: none;
		input[type=text] { 
			width: 100%;
			padding: 8px 15px;
			background-color: #e4e4e4;
			color: #333;
			outline: none;
			font-size: 16px;
			line-height: 24px;
			&:focus, &::placeholder {
				color: rgba(#333,.3);
				font-family: arial, sans-serif;
				font-style: italic;
			}
	}
}	
.welcom-text p{
	font-size: 0.9rem;
}
//=========== Filter ==========//
.panel-heading {
	background-color: $dark-blue;
	  a {
			color: #fff;
			}
			&:hover, &:focus {
				background-color: $accent;
				color:#fff;
			}
	}
.btn-filter {
	background-color: #ccc;
	padding: 0.5rem 1.8rem;
	color: #fff;
	font-size: 1.0rem;
	  &:hover, &:focus {
			background-color: $accent;
			color:#fff;
			
		}
}
.panel-title {
	background-color: #f0f0f0;
}
a:hover,a:focus{
	text-decoration: none;
	outline: none;
}
#accordion .panel-heading{
	padding: 0;
	border: none;
	border-radius: 0;
}
#accordion .panel-title a {
	display: block;
  padding: 4px 10px;
  background: $light-blue;
  font-size: 14px;
	font-weight: lighter;
	color: $accent;
	box-shadow: 0 0 10px rgba(0,0,0,.05);
	position: relative;
	transition: all 0.5s ease 0s;
	box-shadow: 0 1px 2px rgba(43,59,93,0.30);
}
#accordion .panel-title a.collapsed{
	box-shadow: none;
	color: #fff;
	box-shadow: 0 1px 2px rgba(43,59,93,0.30);
}
#accordion .panel-title a:before,
#accordion .panel-title a.collapsed:before{
	  content: "\f067";
    font-family: "Font Awesome";
    width: 20px;
    line-height: 16px;
    font-size: 15px;
    font-weight: 400;
    color: $accent;
    text-align: center;
    position: absolute;
    top: 8px;
    right: 15px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}
#accordion .panel-title a.collapsed:before{
	color: #fff;
	transform: rotate(0);
}
#accordion .panel-body{
	padding: 0 15px;
	border: none;
	background-color: #f0f0f0;
	font-size: 0.8rem;
	color: #333;
	line-height: 22px;
	text-decoration: none;
		ul {padding-left: 0;}
		li {
			display: block;
			border-bottom: #e4e4e4 1px solid;
				a {
					padding: 0;
					line-height: 0.9rem;
					color: $dark;
					  &:hover, &:focus {
						  color: $accent;
						}
						&::before {
							font-family: "Font Awesome";
							font-size: 0.7rem;
							font-weight: 300;
							content: "\f067";
							padding-right: 6px;
						}
				}	
						&::after {
						content:'';
						}
						&:last-child {
							border-bottom: none;
					}
	}	
}
/*==========  Desktop First  ==========*/
@media (min-width: 1500px) {
.container {
		min-width: 1400px;
	}	
}
// ≥1200px
@include media-breakpoint-down(lg) {
	
}
// ≥992px
@include media-breakpoint-down(md) {
	.desc {
		padding: 5% 10% 5% 10%;
		position: relative;
			p {
				font-size: 2.0vw;
				line-height: normal;
			}
			h2 {
				font-size: 3.6vw;
				line-height: 3.2vw;
			}
	}
  .main-menu {
    display: none;
		padding: 20px;
	}
  .mobile-menu-button { 
    display: block;
	}
}
// ≥768px
@include media-breakpoint-down(sm) {
	.desc {
		padding: 5% 10% 5% 10%;
		transform: skewX(0deg);
		position: relative;
			p {
				font-size: 2.0vw;
				text-align: left;
				line-height: normal;
				padding-bottom: 1.0rem;
			}
			h2 {
				font-size: 3.6vw;
				color:#fff;
				text-align: left;
				line-height: 3.2vw;
				padding-bottom: 0;
			}

	}
	.review  {
		transform: skewX(0deg);
		margin-left: 0;
		height: 100%;
	}
	.item.list-group-item .img-event {
    float: none;
    width: 54%;
    margin: 0 auto;
	}
	.item.list-group-item .caption {
    width: 100%;
    margin: 0;
    padding-top: 0;
  }
}

// ≥576px
@include media-breakpoint-down(xs) {
	.book-desc {
		display: none;
	}
}
@media (min-width: 768px) {
	.footer-bs .footer-nav, 
	.footer-bs .footer-ns { 
		border-left:solid 1px rgba(255,255,255,0.10);
	}
}
/*==========  Mobile First  ==========*/
@include media-breakpoint-up(xs) {
	.mobile-menu-button {
    padding: 0 40px;
}
.brand {
	padding: 4px 0 0 10px;
}
.search-wrap .search {
	
	padding: 0 10px 0 10px;
}

}
// ≤576px
@include media-breakpoint-up(sm) {

}
// ≤768px
@include media-breakpoint-up(md) {

}
// ≤992px
@include media-breakpoint-up(lg) {

}
// ≤1200px
@include media-breakpoint-up(xl) {
	
}
