.banner-sm {
  background-color: #e4e4e4;
  min-height: 100px;
  margin-bottom: 3.0rem;
}
.banner-rubric {
  background-color:#002e38;
  position: relative;
  min-height: 60px;
  p {
    font-family: "Open Sans Condensed",sans-serif;
    font-size: 3.2rem;
    color: #dcdcdc;
    position: absolute;
    bottom: 0rem;
    line-height: 2.0rem;
    font-weight: 600;
  }
}
.banner-rubric-books {
  background-color:$accent;
  position: relative;
  min-height: 60px;
  p {
    font-family: "Open Sans Condensed",sans-serif;
    font-size: 3.2rem;
    color: #333;
    position: absolute;
    bottom: 0rem;
    line-height: 2.0rem;
    font-weight: 600;
  }
}
.b-contact {
  width: 100%;
}
.mybook {
  background-color: #efefef;
  color: #fff;
  height: 380px;
  position: relative;
  overflow: hidden;
}
.rotate {
  transform: rotate(-15deg);
  perspective: 1000px;
}
.phone {
  width: 280px;
  height: 427px;
  left: 5%;
  top: 2rem;
  background: #fff url('../img/covers/cov-b-1.jpg') center no-repeat;
  z-index: 3;
  position: absolute;
  animation-delay:0.5s;
}
.tablet {
  width: 280px;
  height: 427px;
  background: #fff url('../img/covers/cov-b-2.jpg');
  top: 4rem;
  left: 25%;
  z-index: 5;
  position: absolute;
  animation-delay:1s;
}
.desctop {
  width: 280px;
  height: 427px;
  background: #fff url('../img/covers/cov-b-3.jpg');
  top: 7rem;
  left: 50%;
  z-index: 10;
  position: absolute;
  animation-delay:1.5s;
  transform: rotateY(45deg);
}
