.footer-bs {
  background-color: $light-blue;
  padding: 60px 40px;
  color: rgba(255,255,255,1.00);
  margin-bottom: 0;
}
.footer-bs .footer-brand, .footer-bs .footer-nav, .footer-bs .footer-policy, .footer-bs .footer-ns { 
  padding:10px 25px; 
}
.footer-bs .footer-nav, .footer-bs .footer-policy, .footer-bs .footer-ns { 
  border-color: transparent; 
}
.footer-bs .footer-brand h2 { 
  margin:0px 0px 0px; 
}
.footer-bs .footer-brand p { 
  font-size: .7rem;
  color: #9e9e9e;
  line-height: 1.0rem;
  padding-right: 30px;
}
.footer-bs .footer-nav ul.pages,
.footer-bs .footer-policy ul.pages { 
  list-style:none; 
  padding:0px; 
}
.footer-bs .footer-nav ul.pages li { 
   padding:0 0;
   line-height: 1.0rem;
     a { 
       color: #9e9e9e; 
       font-weight:400;
       text-transform:uppercase;
       font-size: 0.8rem; 
     }
}
.footer-bs .footer-policy ul.pages li { 
  padding:0 0;
  line-height: 1.2rem;
    a { 
      color: #9e9e9e; 
      font-weight:400;
      text-transform:uppercase;
      font-size: 0.7rem; 
    }
}
.footer-bs .footer-nav ul.pages a:hover { 
  color:rgba(255,255,255,0.80); 
  text-decoration:none; 
}
.footer-bs h4 {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0;
  font-weight: 400;
  color: #e4e4e4
}
.newsletter {
  padding: 80px 0;
}
.newsletter .content {
  max-width: 650px;
  margin: 0 auto;
  position: relative;
  z-index: 2; }
  .newsletter .content h2 {
  color: $dark;
  margin-bottom: 40px;
}
  .newsletter .content .form-control {
    height: 38px;
    background-color: #e4e4e4;
    border: none;
    border-radius: 0;
    padding-left: 10px;
    font-size: 0.9rem;
    font-style: italic;
      &:focus {
        background-color: #fff;
      }
  }
  .newsletter .content.form-control:focus {
  box-shadow: none;
  border: 2px solid #243c4f;
  }
  .newsletter .content .btn {
  font-family: "Font Awesome", sans-serif;
  padding: 0 1.0rem;
  color: #fff;
  line-height: 1.35; 
  border-radius: 0;
  background: #243c4f;
  color: #fff;
    &:hover {
      background-color: $accent;
      color: $dark;
    }
  }
.footer-bs .footer-social ul { 
  list-style:none; 
  padding:0px; 
}
.footer-bs .footer-social p {
  font-family: "Font Awesome", sans-serif;
  padding:5px 4px;
}
.footer-bs .footer-social a { 
  color:#999;
}
.footer-bs .footer-social a:hover { 
  color:rgba(255,255,255,0.80); 
  text-decoration:none; 
}
.footer-bs .footer-ns p { 
  font-size:0.9rem; 
  color:#9e9e9e; 
}
.copy {
  background-color: $accent;
  padding: 10px;
  font-size: 0.8rem;
  font-weight: 400;
    a {
      color: $dark;
        &:hover {
          color: $light-blue;
          text-decoration: underline;
        } 
    }
}
