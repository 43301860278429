.carousel-inner {
	padding: 0 0;
}
.b-right {
	background-color: $accent;;
}
.review  {
	background-color: $accent;
	position: relative;
	transform: skewX(-9deg);
	margin-left: -46px;
	height: 100%;
}
.desc {
  padding: 5% 20% 10% 10%;
	transform: skewX(9deg);
	bottom: 0;
	position: absolute;
	  p {
			font-family: $default-font;
		  font-size: 1.0vw;
			text-align: left;
			line-height: normal;
			font-weight: lighter;
			}
	  h2 {
			font-family: 'Open Sans Condensed', sans-serif;
		  font-size: 2.6vw;
			color:#fff;
			text-align: left;
			font-weight: 300;
			line-height: 2.1vw;
			padding-bottom: 1.0rem;
		}
		.review-title {
			width: 30%;
		}
}
.bestseller {
	background-color: #efefef;
	padding-top: 20px;
	h3 {
		font-size: 1.6rem;
		color: $dark-blue;
		font-weight: 400;
	}
}
.pagination-container {
		margin: 5px 0;
		background-color: #f4f4f4;
		padding: 1px 0px 1px 10px;
		line-height: 2em;
}
[class*=' imghvr-'], [class^=imghvr-] {
	text-align: center;
}
[class*=' imghvr-'] figcaption, [class^=imghvr-] figcaption {
	top: -1px;
	padding: 30% 10px 10px 10px;
}
