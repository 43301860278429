// Variables Bootstrap 4
$red:     #cc2eaa;
$green:   #2ecc71;
$primary: #FF9966;
$border-radius: 0rem !default;
$border-radius-lg: 0rem !default;
$border-radius-sm: 0rem !default;

$default-font: "Roboto", sans-serif;
$accent: #FF9966;
$text: #333;
$dark: #202222;
$dark-blue: #1c2026;
$light-blue: #3c4655;
$image-bg: rgba($dark-blue, 0.7)!default;
$figcaption-bg: rgba($dark-blue, 0.7)!default;

$grid-gutter-width: 20px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 640px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

$gutter: $grid-gutter-width;

.btn-primary {
  color: #ffffff;
}

$btn-border-radius:           0;
$btn-border-radius-lg:        0;
$btn-border-radius-sm:        0;